import * as React from "react";
import { graphql } from "gatsby";

import HeaderAndMeta from "../components/HeaderAndMeta";
import Footer from "../components/Footer";
import KYCLanding from "../components/KYCLanding";

const KYCPage = () => {
  return (
    <main className="kyc-section">
      <HeaderAndMeta />
      <KYCLanding />
      <Footer />
    </main>
  );
};

export default KYCPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
