import React from "react";
import { Trans } from "gatsby-plugin-react-i18next";

import KYCLandingFormLazyLoader from "./KYCLandingLoader";

const KYCLanding = () => {
  return (
    <section id="home" className="w-full relative z-20 overflow-hidden">
      <div className="absolute right-0 top-0 w-200 h-200 lg:w-320 lg:h-320">
        <img
          className="w-full h-full object-contain"
          width="320"
          height="320"
          src="/images/icons/top-rarr.svg"
          alt="right-arrow"
        />
      </div>
      <div className="pt-100 md:pt-120 lg:pt-144 container">
        <div className="flex items-start flex-col lg:flex-row relative overflow-visible">
          <div className="text-navy-blue lg:shrink-0 lg:w-640">
            <h1 className="font-heading font-bold text-28 md:text-48">
              <Trans>KYC/AML</Trans>
            </h1>
            <div className="md:max-w-[500px] mt-32" id="veriff-holder">
              <KYCLandingFormLazyLoader />
            </div>
          </div>
          <div className="ml-auto lg:ml-20 hidden md:block max-md:-mt-100 max-md:-mb-80 lg:-my-0 items-center shrink-0 illustration">
            <div className="w-full h-full opacity-0 -translate-x-1/2 animate-from-bottom">
              <img
                className="w-full object-contain"
                width="508"
                height="488"
                src="/images/Illustration-grant.svg"
                alt="illustration"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default KYCLanding;
